


























import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';
import { cleanBloomreachImageUrl, prepareBloomreachProductPath } from '~/helpers/bloomreach/bloomreachData';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import { SIMPLIFIED_CARD } from '~/constants/productCard';

export default defineComponent({
  name: 'BloomreachProductCart',
  components: {
    Link,
    TwicpicsImage
  },
  props: {
    product: {
      type: Object as PropType<BloomreachProduct>,
      required: true
    }
  },
  setup(props) {
    const cleanedImageUrl = computed(() => cleanBloomreachImageUrl(props.product.image));
    const productUrlPath = computed(() => prepareBloomreachProductPath(props.product.url));
    const price = computed(() => {
      return Number.isInteger(props.product.price_local_currency)
        ? props.product.price_local_currency + '.00'
        : props.product.price_local_currency;
    });
    const isDiscounted = computed(() => {
      return props.product.discount_value_local_currency > 0;
    });
    return {
      cleanedImageUrl,
      productUrlPath,
      LinkType,
      SIMPLIFIED_CARD,
      price,
      isDiscounted
    };
  }
});
