

















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { cartGetters } from '@vsf-enterprise/commercetools';
import SideBar from '~/components/organisms/SideBar/SideBar.vue';
import { useUiState, useCartExtended, useRouteExtended, useLineItems } from '~/composables';
import { ROUTES } from '~/constants/routes';
import CartSummary from '~/components/organisms/Cart/CartSummary.vue';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SideBar,
    CartSummary
  },
  setup(_props, { root: { $router } }) {
    const { isCartDrawerOpen, toggleCartDrawer } = useUiState();
    const { cart } = useCartExtended();
    const { getAdjustedSlug } = useRouteExtended();

    const { lineItems } = useLineItems();

    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const total = computed(() => getTotalGrossPrice(cart.value));

    const handleClickCheckout = () => {
      toggleCartDrawer();
      $router.push(getAdjustedSlug(ROUTES.CHECKOUT_ADDRESSES));
    };

    return {
      isCartDrawerOpen,
      toggleCartDrawer,
      lineItems,
      handleClickCheckout,
      totalItems,
      total
    };
  }
});
